.icon-link() {
  text-decoration: none;

  span:before,
  &:before {
    .transition(color ease-in-out .15s);
    color: @gray;
    font-size: 1.2em;
  }

  &:hover,
  &:focus {
    color: @link-hover-color
  }

  &:hover:before,
  &:focus:before,
  &:hover span:before,
  &:focus span:before {
    color: @brand-primary
  }
}


//Editor specific
.editor-paragraph() {
  margin-bottom: 1.5em;
}

.editor-headings() {
  margin: 0 0 0.5em 0;
}

.editor-table() {
  .table;
  .table-condensed();
  .table-striped()
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.editor-img.mini-width {
  width: 20%;
}

.editor-img.mini-width img {
  max-width: 100%;
  height: auto;
}