

html, body {height:100%; padding:0; margin:0;}
.error-page {
	text-align:center;
	height:100%;
	
	&:before {
		@media screen and (min-width:@screen-sm) {
		
			margin-left:-1px;
			display: inline-block;
			height: 100%;
			width: 1px;
			vertical-align: middle;
		}
	}
	
	.logo {
		padding:20px 40px 40px;
		
		max-width:100%; 
		
		
		a { text-align:center;}
		img {width:350px; margin:0 auto;}
		
		@media screen and (max-width:@screen-xs-max) { 
			padding:20px;
			img {width:250px;} 
			
		}
		@media screen and (min-width:@screen-sm) {
		
			padding:30px;
		}
	}
	
	.error-message {
		display:inline-block;
		width:90%;
		
		@media screen and (min-width:@screen-sm) {
			padding:30px 0;
			margin:0 auto 0 auto;
			vertical-align:middle;
			
		}
		
		h1 {
			font-size:120px;
			line-height:1em;
			
			@media screen and (min-width:@screen-sm) {
				font-size:325px;	
			}
		}
		p {
			@media screen and (min-width:@screen-sm) {font-size:16px;}
			&.error-lead {
				font-family:@headings-font-family;
				font-size:20px;
				font-weight:600;
				@media screen and (min-width:@screen-sm) {font-size:28px;}
			}
		}
	}
	
	.links {
		.make-row();
		max-width:600px;
		margin:0 auto;
		@icon-height:75px;
		a {
			
			.icon-link();
			.make-xs-column(6);
			.make-sm-column(6);
		
			@media screen and (max-width:@screen-xs-max) {padding-top:((@icon-height/1.5) + 10);}
			margin-top:25px;
			
			
		}
		
		.home:before {.fa; content:@fa-var-home; }
		.search:before {.fa; content:@fa-var-search; }
		
	}
}